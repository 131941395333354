@tailwind base;
@tailwind components;
@tailwind utilities;

/*Dropdown*/
.group:hover .group-hover\:scale-100 { transform: scale(1) }
.group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
.scale-0 { transform: scale(0) }

*{
    scroll-behavior: smooth;
}

*::selection{
    background-color: #C05D71;
    color: white;
}

@layer base {
    main{
        @apply overflow-hidden;
    }

    h2{
        @apply font-petersburg font-bold text-2xl md:text-3xl text-brown mb-4 md:mb-8;
    }

    p{
        @apply font-sofia mb-4 text-brown font-light sm:text-base;
    }
}

@layer components {

}
